import React from 'react';

const CollectingAreaModal = () => (
	<div>
		<h1>Discharge Width</h1>
		<p>The width of the combine discharge (or the sieve width) in inches or centimetres.</p>
	</div>
);

export default CollectingAreaModal;
