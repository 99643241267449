import React from 'react';

const CombineInfo3 = (props) => (
  <div>
    <p>Some combines have access to view returns from cab.</p>

    <p>If no access from cab:</p>
    <ol>
      <li>
        Open cover on bottom of returns elevator and harvest at target speed until steady state is reached.
      </li>
      <li>
        Assess the material from the returns.  Ideally, material in the returns system should be 30% grain or less.
      </li>
    </ol>
  </div>
);

export default CombineInfo3;
