import React from 'react'

const BlacklegIncidencePercent = () => (
	<div>
		<h1>Blackleg Incidence Percentage</h1>
		<p>Determine the blackleg incidence percentage by dividing the number of infected plants surveyed by the total number of plants surveyed.</p>
		<p><strong>For example:</strong> in a field where 100 plants were surveyed (20 plants surveyed in 5 different areas) and 60 of them exhibited blackleg symptoms, there is a 60% blackleg incidence.</p>
	</div>
)
	
export default BlacklegIncidencePercent