import React from 'react'
import styled  from 'styled-components'
import Severity0 from 'images/Sev0.png'
import Severity1 from 'images/Sev1.png'
import Severity2 from 'images/Sev2.png'
import Severity3 from 'images/Sev3.png'
import Severity4 from 'images/Sev4.png'
import Severity5 from 'images/Sev5.png'


const List = styled.ol`
	padding: 0;

	img {
		max-width: 500px;
		margin-bottom: 10px;
	}

	@media (max-width: 580px) {
		img {
			max-width: 100%;
		}
	}
`

const ListItem = styled.li`
	list-style: none;
`

const ListContent = styled.div`
	margin-bottom: 15px;
	display: flex;
	flex-wrap: nowrap;
	align-items: flex-start;

	p { margin-top: 0; margin-bottom: 0 }
`

const Numbers = styled.p`
	font-weight: 600;
	border-radius: 100%;
	border: 2px solid black;
	width: 1.7rem;
	height: 1.7rem;
	display: inline-block;
	text-align: center;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	margin-right: 15px;
`

const BlacklegSeverityRating = () => (
	<div>
		<h1>Blackleg Severity Rating</h1>
		<p>
			For more information, visit <a href="https://www.canolacouncil.org/canola-encyclopedia/diseases/blackleg/" target="_blank" rel="noreferrer">Blackleg.ca.</a>
		</p>
		<List>
			<ListItem>
				<img src={Severity0} alt="black severity level 0" />
					
				<ListContent>
					<Numbers>0</Numbers>
					<p>No diseased tissue visible in the cross-section.</p>
				</ListContent>      
			</ListItem>

			<ListItem>
				<img src={Severity1} alt="black severity level 1" />
					
				<ListContent>
					<Numbers>1</Numbers>
					<p>Diseased tissue occupies up to 25 per cent of the cross-section.</p>
				</ListContent>
			</ListItem>

			<ListItem>
				<img src={Severity2} alt="black severity level 2" />
					
				<ListContent>
					<Numbers>2</Numbers>
					<p>Diseased tissue occupies 26&ndash;50 per cent of the cross-section.</p>
				</ListContent>
			</ListItem>

			<ListItem>
				<img src={Severity3} alt="black severity level 3" />
					
				<ListContent>
					<Numbers>3</Numbers>
					<p>Diseased tissue occupies 51&ndash;75 per cent of the cross-section.</p>
				</ListContent>
			</ListItem>

			<ListItem>
				<img src={Severity4} alt="black severity level 4" />
					
				<ListContent>
					<Numbers>4</Numbers>
					<p>Diseased tissue occupies greater than 75 per cent of the cross-section with little or no constriction of affected tissues.</p>
				</ListContent>
			</ListItem>

			<ListItem>
				<img src={Severity5} alt="black severity level 5" />
					
				<ListContent>
					<Numbers>5</Numbers>
					<p>Diseased tissue occupies 100 per cent of the cross-section with significant constriction of affected tissues; tissue dry and brittle; plant dead.</p>
				</ListContent>
			</ListItem>
		</List>
	</div>
)

export default BlacklegSeverityRating;