import React from 'react';
import Img from 'images/modal/weed-control.jpg'

const WeedControl = (props) => (
  <div>
    <h1>Weed control</h1>
    { /* eslint-disable-next-line react/jsx-no-target-blank */ }
    <p>Crop competition is a great way to help control weeds in-season and slow the spread of herbicide-tolerant weeds long term. If you are seeding into fairly weedy conditions or if you are concerned about herbicide-tolerant weeds, it is a good idea to increase canola plant density, which will help shade and choke out emerging weeds. Even in average field conditions, extra crop competition can eliminate the need for a second pass of in-crop herbicide, saving growers $10 or more per acre and taking some of the strain off of their sprayers. <a href="http://www.canolacouncil.org/canola-encyclopedia/crop-establishment" target="_blank">For more information</a></p>
    <img src={Img} alt="Weed control"/>
  </div>
);

export default WeedControl;
