function showResult(state = false, action) {
  switch (action.type) {
    case 'SHOW_RESULT':
      return true;

    case 'HIDE_RESULT':
      return false;

    case 'VIEW_SAVED_FIELD':
      return true;

    default:
      return state;
  }
}

export default showResult;
