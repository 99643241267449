import React from 'react';
import Img from 'images/modal/snow-on-canola.jpg'

const GrowingSeason = (props) => (
  <div>
    <h1>Length of growing season</h1>
    { /* eslint-disable-next-line react/jsx-no-target-blank */ }
    <p>Canola plants growing in low density will tend to be larger and have more secondary branches than those growing in high densities. Secondary branches flower later and mature later than main stems, meaning thin crops will usually mature later and have a broader range of maturity. Pods on the main stem may be dry with completely mature seeds while pods on secondary branches may still be green with watery seeds inside. Top pods may start to shell out before pods on side branches are ready to swath. Yield losses will occur no matter when a grower decides to swath this crop. Additionally, later maturing crops are more likely to encounter a fall frost that arrests the development of immature seeds and leads to shriveled seed and higher chlorophyll counts. This may result in lost yield or grade. <a href="http://www.canolacouncil.org/canola-encyclopedia/crop-establishment/" target="_blank">For more information</a></p>
    <img src={Img} alt="Length of growing season"/>
  </div>
);

export default GrowingSeason;
