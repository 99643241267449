import trim from 'lodash/trim';
import get from 'lodash/get';
import round from 'lodash/round';

// Reducer for all fields
function fields(state = {}, action) {
  switch (action.type) {
    case 'CHANGE_FIELD':
      let absValue;

      if (!isNaN(action.value)) {
        absValue = trim(action.value);
      }
      else if (action.value.length < 1) { // Allow empty fields
        absValue = '';
      }
      else {
        absValue = state[action.name];
      }

      switch (action.name) {
        case 'standUniformity':
        case 'frostRisk':
        case 'weedControl':
        case 'insectDamage':
        case 'growingSeason':
          switch (parseFloat(action.value)) {
            case 1:
              absValue = 5;
              break;
            case 2:
              absValue = 4;
              break;
            case 4:
              absValue = 2;
              break;
            case 5:
              absValue = 1;
              break;
          }
          break;
      }

      let newState = Object.assign({}, state, { [action.name]: absValue }); // Value needs to be a string.

      switch (action.result) {
        case 'targetPlantDensity':
          let targetPlantDensity = round(getTargetPlantDensity(newState));
          return Object.assign({}, newState, { targetPlantDensity });

        case 'seedingRate':
          let seedingRate = round(getSeedingRate(newState), 1);
          return Object.assign({}, newState, { seedingRate });

        case 'plantSurvival':
          let plantSurvival = round(getPlantSurvival(newState));
          return Object.assign({}, newState, { plantSurvival });

        case 'plantDensity':
          let plantDensity = round(getPlantDensity(newState));
          return Object.assign({}, newState, { plantDensity });

        case 'seedDensity':
          let seedDensity = round(getSeedDensity(newState));
          return Object.assign({}, newState, { seedDensity });

        default:
          return newState;
      }

    case 'LOAD_FROM_QUERY_STRING':
      // Load fields from email...
      const valuesStr = action.params.get('v');

      if (valuesStr) {
        const fieldNames = Object.keys(state);
        const valuesArr = valuesStr.split(',');

        if (fieldNames.length === valuesArr.length) {
          const sortedFieldNames = fieldNames.sort();
          const loadedFields = {};
          let hasNaN = false;

          valuesArr.forEach((v,i) => {
            if (isNaN(v)) {
              hasNaN = true;
              return;
            }

            let value = parseFloat(v);
            loadedFields[sortedFieldNames[i]] = (value < 0 ? 0 : value);
          });

          if (hasNaN) {
            return state;
          }

          return loadedFields;
        }
      }

      let seedingRate = round(getSeedingRate(state), 1);
      let plantSurvival = round(getPlantSurvival(state));
      let plantDensity = round(getPlantDensity(state));
      let seedDensity = round(getSeedDensity(state));
      return Object.assign({}, state, { seedingRate, plantSurvival, plantDensity, seedDensity });

    default:
      return state;
  }
}

export default fields;

// See Background algorythms in GDrive
function getTargetPlantDensity(state) {

  // Frost Risk
  let frostRiskValue = 4;
  if (state.frostRisk > 3) {
    frostRiskValue = 9;
  }
  else if (state.frostRisk > 1) {
    frostRiskValue = 6;
  }

  // Growing Season
  let growingSeasonValue = 4;
  if (state.growingSeason > 3) {
    growingSeasonValue = 9;
  }
  else if (state.growingSeason > 1) {
    growingSeasonValue = 6;
  }

  // Insect Damage
  let insectDamageValue = 4;
  if (state.insectDamage > 3) {
    insectDamageValue = 9;
  }
  else if (state.insectDamage > 1) {
    insectDamageValue = 6;
  }

  // Weed Control
  let weedControlValue = 4;
  if (state.weedControl > 3) {
    weedControlValue = 9;
  }
  else if (state.weedControl > 1) {
    weedControlValue = 6;
  }

  // Stand Uniformity
  let standUniformityValue = 4;
  if (state.standUniformity > 3) {
    standUniformityValue = 9;
  }
  else if (state.standUniformity > 1) {
    standUniformityValue = 6;
  }

  // Formula
  return (frostRiskValue + growingSeasonValue + insectDamageValue + weedControlValue + standUniformityValue) / 5;
}

function getSeedingRate(state) {
  if (!isNaN(state.targetPlantDensity) && !isNaN(state.tsw) && !isNaN(state.estimatedSurvival)) {
    const _targetPlantDensity = parseFloat(state.targetPlantDensity);
    const _tsw                = parseFloat(state.tsw);
    const _estimatedSurvival  = parseFloat(state.estimatedSurvival);

    if (_targetPlantDensity > 0 && _tsw > 0 && _estimatedSurvival > 0) {
      return (((9.6 * _targetPlantDensity) * _tsw) / _estimatedSurvival);
    }
  }

  return 0;
}

function getPlantSurvival(state) {
  if (!isNaN(state.density) && !isNaN(state.tsw) && !isNaN(state.seedingRate)) {

    const _density     = parseFloat(state.density);
    const _tsw         = parseFloat(state.tsw);
    const _seedingRate = parseFloat(state.seedingRate);

    if (_density > 0 && _tsw > 0 && _seedingRate > 0) {
      return ((48 * _density) * _tsw) / (5 * _seedingRate);
    }
  }

  return 0;
}

function getPlantDensity(state) {
  if (!isNaN(state.desiredSeedingRate) && !isNaN(state.tsw) && !isNaN(state.estimatedSurvival)) {

    const _desiredSeedingRate = parseFloat(state.desiredSeedingRate);
    const _tsw                = parseFloat(state.tsw);
    const _estimatedSurvival  = parseFloat(state.estimatedSurvival);

    if (_desiredSeedingRate > 0 && _tsw > 0 && _estimatedSurvival > 0) {
      return ((5 * _desiredSeedingRate) * _estimatedSurvival) / (48 * _tsw);
    }
  }

  return 0;
}

function getSeedDensity(state) {
  if (!isNaN(state.seedingRate) && !isNaN(state.tsw)) {

    const _seedingRate = parseFloat(state.seedingRate);
    const _tsw         = parseFloat(state.tsw);

    if (_seedingRate > 0 && _tsw > 0) {
      return (500 * _seedingRate) / (48 * _tsw);
    }
  }

  return 0;
}
