import React from 'react';
import styled  from 'styled-components';
import config from 'config'

// These are for mapping stuff to props
import { connect } from 'react-redux';
import { toggleModal } from 'actions/index';
import { loadStateFromLocalstorage, resetSurvey } from 'actions/canolacounts';


import Button from 'components/shared/Button'
import Separator from 'components/shared/Separator'

import { ReactComponent as IconInfo }  from 'images/info-icon.svg';

const Heading = styled.h1`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.4rem;
	font-weight: 400;

	@media (min-width: 640px) {
		font-size: 1.6rem;
	}

	svg { 
		margin-right: 10px;
		flex-shrink: 0;

		* { fill: ${config.colors.red}!important; }
	}
`

class RestoreState extends React.Component {
	constructor(props) {
		super(props)

		this.restoreSession = this.restoreSession.bind(this)
		this.deleteSession = this.deleteSession.bind(this)
	}

	restoreSession () {
		console.log('Restoring state...')
		this.props.loadStateFromLocalstorage()
		this.props.toggleModal('')
	}

	deleteSession () {
		this.props.resetSurvey()
		this.props.toggleModal('')
	}

	render () {
		return (
			<div>
				<Heading>
					<IconInfo width="40" />
					Notice
				</Heading>

				<p style={ {textAlign: 'center'} }>It appears as though you have left with unsaved data.</p>
				<Separator style={ {margin: '5px 0'} } />
								
				<Button buttonStyle="primary" className="small centered" onClick={ this.restoreSession }>Restore Session</Button>
				<Button buttonStyle="caution" className="small centered" onClick={ this.deleteSession }>Delete</Button>
			</div>
		)
	}
}

const actionCreators = {
	toggleModal,
	loadStateFromLocalstorage, 
	resetSurvey,
}

// connect() automatically wraps actionCreators bindActionCreators(), which wraps dispatch()
export default connect(null, actionCreators)(RestoreState)
