import floor from 'lodash/floor';

function fieldName(state = {}, action) {
  switch (action.type) {
    case 'HANDLE_EMAIL_CHANGE':
      return Object.assign({}, state, { value: action.value });

    case 'CHANGE_FIELD':
    case 'RESET_EMAILING_STATE':
      return Object.assign({}, state, { saving: false, saved: false, value: '', emailing: false }); // Reset

    case 'SHOW_EMAIL_FIELD':
      return Object.assign({}, state, { saving: false, saved: false, value: '', emailing: true });

    case 'SEND_EMAIL':
      const sortedFieldNames = Object.keys(action.fields).sort();
      const valuesArr = sortedFieldNames.map((k,i) => action.fields[k]);
      const valuesStr = encodeURI(valuesArr.join(','));
      const isMetric = action.metric;
      const url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${action.pathname}?v=${valuesStr}&metric=${(isMetric ? '1' : '0')}`;

      let resultName;
      let resultValue;

	  // Normalize pathname to strip the base URL
	  const pathname = action.pathname.replace(process.env.PUBLIC_URL, '');

    // This popus up the span in SeedingRate.js when the "Send Email" button is pressed
    let emailConfirmation = document.querySelector(".email-confirmation");
    emailConfirmation.style.display = "flex"; 
    
      // Determine the necessary fields to pass to the server based on the pathname.
      switch (pathname) {
        case '/target-plant-density':
          resultName = 'Target Plant Density';
          resultValue = `${action.fields.targetPlantDensity} plants/ft2`;
          if (isMetric) {
            resultValue = `${floor(parseFloat(action.fields.targetPlantDensity) / 0.0929)} plants/m2`;
          }
          break;

        case '/seeding-rate':
          resultName = 'Seeding Rate';
          resultValue = `${action.fields.seedingRate} lbs/ac`;
          break;

        case '/seeding-rate/plant-survival':
          resultName = 'Emergence';
          resultValue = `${action.fields.plantSurvival} %`;
          break;

        case '/seeding-rate/plant-density':
          resultName = 'Plant Density';
          resultValue = `${action.fields.plantDensity} plants/ft2`;
          if (isMetric) {
            resultValue = `${floor(parseFloat(action.fields.plantDensity) / 0.0929)} plants/m2`;
          }
          break;

        case '/seeding-rate/seed-density':
          resultName = 'Seed Density';
          resultValue = `${action.fields.seedDensity} seeds/ft2`;
          if (isMetric) {
            resultValue = `${floor(parseFloat(action.fields.plantDensity) / 0.0929)} seeds/m2`;
          }
          break;

        default:
          console.warn('No matching email path for route')
          return Object.assign({}, state, { saving: false, saved: false, value: '', emailing: false }); // Reset
      }

      const phpEmailerPath = process.env.PUBLIC_URL + '/sendemail.php'
      const data = {
        to: action.email,
        resultName: resultName,
        resultValue: resultValue,
        url: url
      }
      const options = {
        method: 'POST',
        body: JSON.stringify(data)
      }

      fetch (phpEmailerPath, options)
        .then( response => response.json() )
        .then( response => data.status === 'error' ?
          Promise.reject(data.message) :
          Promise.resolve(data.message)
        )
        .then ( response => {console.log('Email sent')})
        .catch( error => {console.error('Error sending email:', error)})

      return Object.assign({}, state, { saving: false, saved: false, value: '', emailing: false }); // Reset

    default:
      return state;
  }
}

export default fieldName;
