import { saveState } from '../localStorage';

function beforeYouStartAccordionOpen(state=true, action) {
  switch (action.type) {
    case 'TOGGLE_BEFORE_YOU_START_ACCORDION_OPEN':
      saveState('beforeYouStartAccordionOpen', !state);
      return !state;
    default:
      return state;
  }
}

export default beforeYouStartAccordionOpen;
