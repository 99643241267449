import React from 'react';
import styled  from 'styled-components';

// These are for mapping stuff to props
import { connect } from 'react-redux';
import { toggleModal } from 'actions/index';

import Button from 'components/shared/Button'
import Separator from 'components/shared/Separator'

import { ReactComponent as IconCanolaCounts }  from 'images/icon-canola-counts.svg';

const List = styled.ol`
	list-style: none;
	counter-reset: listCounter;
	padding: 0;
`

const ListItem = styled.li`
	counter-increment: listCounter;
	margin-bottom: 15px;
	display: flex;
	flex-wrap: nowrap;
	align-items: flex-start;

	@media (min-width: 640px) {
		align-items: center;
	}

	p { margin: 0; }

	&::before {
		content: counters(listCounter, '');
		font-size: 1em;
		font-weight: 600;
		border-radius: 100%;
		border: 2px solid black;
		width: 1.7rem;
		height: 1.7rem;
		display: inline-block;
		text-align: center;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		margin-right: 15px;
	}
`

const Heading = styled.h1`
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	font-weight: 400;

	@media (min-width: 640px) {
		font-size: 1.6rem;
	}

	svg { 
		margin-right: 10px;
		flex-shrink: 0;
	}
`


class Welcome extends React.Component {

	/* eslint-disable-next-line no-useless-constructor */
	constructor(props) {
		super(props)
	}

	render () {
		return (
			<div>
				<Heading>
					<IconCanolaCounts width="50" />
					Welcome to Canola Counts!
				</Heading>

				<p style={ {fontSize: "1.1em"} }>
					This survey tool was produced by the Canola Council of Canada with funding by Alberta Canola, SaskCanola and the Manitoba Canola Growers. It was built to help drive the adoption of plant establishment assessments while tracking progress towards <a href="https://www.canolacouncil.org/about-us/strategic-plan/#supply" target="_blank">canola industry production goals</a>.
				</p>

				<p>For best results:</p>
				<Separator style={ {margin: '5px 0'} } />
				<List>
					<ListItem>
						<p>
							Take multiple plant counts and submit the average plant density for each field. Learn more about plant establishment with the <a href="https://www.canolacouncil.org/canola-encyclopedia/plant-establishment/" target="_blank">Canola Encyclopedia</a>.
						</p>
					</ListItem>
					<ListItem>
						<p>
							Final submission of this form requires a data connection.
						</p>
					</ListItem>
					<ListItem>
						<p>
							In the event that a connection is lost, your data are saved to this device and are accessible later.
						</p>
					</ListItem>
					<ListItem>
						<p>
							Want a Canola Counts plant density 1/4m<sup>2</sup> ring to take plant counts? Contact us at <a href="mailto:dicksont@canolacouncil.org">dicksont@canolacouncil.org</a>.
						</p>
					</ListItem>
				</List>
				
				<Button buttonStyle="primary" className="small" onClick={ () => this.props.toggleModal('welcome', {useHistory: false, once: true}) }>Start</Button>
			</div>
		)
	}
}

const actionCreators = {
	toggleModal,
}

// connect() automatically wraps actionCreators bindActionCreators(), which wraps dispatch()
export default connect(null, actionCreators)(Welcome)
