import React from 'react';

const CombineInfo6 = (props) => (
  <div>
    <p>To measure loss:</p>
    <ol>
      <li>Drop all residue into a windrow.</li>
      <li>Harvest at target feed rate (speed) and catch all residue in a pan.</li>
      <li>Separate grain loss from MOG.</li>
      <li>Quantify grain (weight, volume, seed count).</li>
      <li>Calculate loss in bu/ac.</li>
    </ol>

    <p>
      For additional reference see SEED LOSS GUIDE:
      { /* eslint-disable-next-line react/jsx-no-target-blank */ }
      <a target="_blank" href="https://www.canolacouncil.org/media/533149/combine_seed_loss_guide.pdf">
        https://www.canolacouncil.org/media/533149/combine_seed_loss_guide.pdf
      </a>
    </p>
  </div>
);

export default CombineInfo6;
