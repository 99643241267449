function openAccordion(state = '', action) {
  switch (action.type) {
    case 'OPEN_ACCORDION':
      return action.name;

    case 'CLOSE_ACCORDIONS':
      return '';

    default:
      return state;
  }
}

export default openAccordion;