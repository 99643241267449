import React from 'react';
import { useHistory, useLocation } from "react-router-dom";

// These are for mapping stuff to props
import { toggleSidebar, hideResult } from '../actions/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const Nav = (props) => {
  //access the current location object, which contains information about the current URL
  const location = useLocation(); 
  //access the history instance, which lets you manage and navigate the browser history
  const browserHistory = useHistory();

  const buttonClassName = (location.pathname === '/') ? 'left' : 'left back';
  const sidebarClassname = props.sidebar ? 'right close' : 'right';
  return (
    <nav id="top-nav">
      
    <button className={sidebarClassname} onClick={() => {
        props.actions.toggleSidebar();
      }}></button>
      <h1>{props.title}</h1>
    </nav>
  );
}

const stateToProps = (state) => {
  return {
    sidebar: state.sidebar,
    showResult: state.showResult
  };
}

const dispatchToProps = (dispatch) => {
  return { actions: bindActionCreators({ toggleSidebar, hideResult }, dispatch) };
}

// Connecting the Nav component to the Redux store
// stateToProps and dispatchToProps are passed, allowing it to access the state and dispatch actions
export default connect(stateToProps, dispatchToProps)(Nav);
