import React from 'react';

const CombineInfo4 = (props) => (
  <div>
    <h1>Power Shutdown Procedure</h1>
    <p>Refer to combine operator manual for correct procedure to conduct a power shutdown. Inspect the uniformity of chaff load across the width of the upper sieve.</p>
  </div>
);

export default CombineInfo4;
