import Logo from 'images/canola-council-of-canada-header-white.png'
import PamiLogo from 'images/pami-logo.png'

import { useLocation } from "react-router-dom";

const Footer = (props) => {
    let location = useLocation();
    const today = new Date();
    const year = today.getFullYear();
    
    // Combine Optimization custom footer
    if (location.pathname === '/combine-optimization') {
        return (
            <footer id="footer">
                <div className="wrap centered has-issues">{ /* eslint-disable-next-line react/jsx-no-target-blank */ }
                    <a href="http://canolacouncil.org/growers/" target="_blank"><img src={Logo} alt="Canola Council of Canada"/></a>
                    <a href="http://pami.ca/" target="_blank" rel="noreferrer"><img className="pami-logo-footer" src={PamiLogo} alt="Canola Council of Canada"/></a>
                    <br />
                    <a className="contact-link" href="mailto:brackenreeda@canolacouncil.org">Click Here to Contact Us or Report Issues</a>
                </div>
            </footer>
        );
    
    // Default footer
    } else {
        return (
            <footer id="footer">
                <div className="wrap">
                    <div className="logo"> { /* eslint-disable-next-line react/jsx-no-target-blank */ }
                        <a href="http://canolacouncil.org/" target="_blank">
                            <img src={Logo} alt="Canola Council of Canada"/>
                        </a><br /><br />
                        <address>
                            Canola Council of Canada<br/>
                            400-167 Lombard Avenue<br/>
                            Winnipeg, MB, R3B 0T6 <br/>
                            Toll-Free: <a href="tel:18668344378">1-866-834-4378</a><br/>
                            Email: <a href="mailto:admin@canolacouncil.org">admin@canolacouncil.org</a>
                        </address>

                    </div>
                    <div className="terms">The Canola Calculator tools are made available to assist users with respect to agronomic practices, but should not be used as a replacement for professional advice. Consultation with an agronomist or lab analysis is strongly recommended prior to implementing any changes in seeding practices. The Canola Council of Canada disclaims any liability for any direct or indirect damages resulting from use of the Canola Calculator tools.
                    <br /><br />
                    &copy; {year} Canola Council of Canada | View our <a href="https://www.canolacouncil.org/privacy-policy/">Privacy Policy</a><br /><br />
                    By clicking any of the links on this website, you are consenting to view materials that may be commercial in nature.<br />
                    All content on this site is property of the Canola Council of Canada. All rights reserved.
                    </div>
                    
                </div>
            </footer>
        );
    }
}

export default Footer;