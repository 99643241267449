import React from 'react';
import Img from 'images/modal/frost-risk.jpg'
const FrostRisk = (props) => (
  <div>
    <h1>Early season frost risk</h1>
    { /* eslint-disable-next-line react/jsx-no-target-blank */ }
    <p>As a general rule, canola seeded in the Canadian prairies during the first week of May tends to produce the highest yield and quality. Some regions still carry a frost risk well into June so make sure you understand the historical frost risk in your area and remember that warm soil temperatures don't decrease frost risk. Frost often causes patchy losses because it tends to move like water, flowing to lower areas of fields. Canola seedlings can often tolerate -5 C frost with superficial damage and will then recover. But warm conditions leading up to and after a sudden frost won't allow cold hardening in seedlings, and then temperatures as warm as -2 may be able to set back or kill canola seedlings. <a href="http://www.canolacouncil.org/canola-encyclopedia/crop-establishment/" target="_blank">For more information</a></p>
    <img src={Img} alt="Early season frost risk"/>
  </div>
);

export default FrostRisk;
