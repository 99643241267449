import { crops, formulas } from './constants.js';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

const formatters = {
	currency: (string) => {
		return isNaN( parseFloat(string) ) ? null : Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format( parseFloat(string) )
	},
	percent: (string) => {
		return isNaN( parseFloat(string) ) ? null : Intl.NumberFormat('en-CA', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 3}).format( parseFloat(string) )
	},
	number: (string) => {
		return isNaN( parseFloat(string) ) ? null : Intl.NumberFormat('en-CA', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 3}).format( parseFloat(string) )
	},
}

const converters = {
	lpaToKph: (value) => parseFloat(value) * 1.12085, // PAMI
	bpaToTph: (value, density) => parseFloat(value) * density * 0.00112085, // PAMI, with corrections from Taryn
	$paTo$ph: (value) => parseFloat(value) / 0.404686, // PAMI, Corrected by Taryn, 2021-01-13
	lbToG: (value) => parseFloat(value) * 453.592, // GooglePAMI
	mlToBu: (value) => parseFloat(value) * 0.0000274967004, // 1 mL = 1 bu / 36,368 mL (PAMI)
	mToFt: (value) => parseFloat(value) * 3.28084, // Google / https://www.extension.iastate.edu/agdm/wholefarm/html/c6-80.html
	cmToIn: (value) => parseFloat(value) / 2.54, // https://en.wikipedia.org/wiki/Conversion_of_units / Google
	m2ToFt2: (value) => parseFloat(value) * 10.7639, // Google
	kphToBpa: (value, density) => parseFloat(value) / (density * 0.00112085 * 1000), // Taryn/CCC
	$ptTo$pb: (value, density) => parseFloat(value) / (1000 * 2.20462 / density) // Taryn/CCC
}

function calculateLoss(method, type, state) {
	if ( isEmpty(method) || isEmpty(type) || isNil(state.crop) || isEmpty(state.crop) )
		return 0;

	let newState = Object.assign({}, state);
	let density = crops.get(state.crop).density

	// All formulats assume imperial - so we must convert the numbers back from metric
	if (newState.metric) {
		// console.log('Metric state pre-conversions')
		// console.log(newState)

		newState.cutWidth = converters.mToFt(state.cutWidth)
		
		// For consistency between metric/imperial, conversion to ft happens in the calculation formula
		newState.dischargeWidth = converters.cmToIn(state.dischargeWidth)
		newState.collectingArea = converters.m2ToFt2(state.collectingArea)
		newState.expectedYield = converters.kphToBpa(state.expectedYield, density)
		newState.price = converters.$ptTo$pb(state.price, density)
		
		// console.log('Imperial state post-conversions')
		// console.log(newState)
	}
	
	let loss = formulas[method][type](newState)
	return loss
}

// Below RGB/Hex functions are from:
// https://stackoverflow.com/a/5624139/3290114
function componentToHex(c) {
	var hex = c.toString(16);
	return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(r, g, b) {
	return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function hexToRgb(hex) {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, function(m, r, g, b) {
		return r + r + g + g + b + b;
	});

	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16)
	} : null;
}

// Custom
function hexToRgbString (hex) {
	let result = hexToRgb(hex)
	return result ?	`${result.r},${result.g},${result.b}` : null;
}



/**
 * Detect if the browser is iOS, and the version
 * to be used for PWA install prompt.
 * 
 * @link https://github.com/bendrucker/is-ios/blob/master/index.js
 */
 function iOS() {
	return typeof navigator !== 'undefined' &&
	(/iPad|iPhone|iPod/.test(navigator.userAgent || '') ||
	(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) // iPad iOS 13
 }		

export { formatters, calculateLoss, converters, hexToRgb, rgbToHex, hexToRgbString, iOS }
