import { saveState } from '../localStorage';

function selectedAnswers(state = [], action) {
  let newState = state.slice(0);
  switch (action.type) {
    case 'ADD_ANSWER':
      if( !newState.includes(action.id) || (newState[action.level] !== action.id) ) {
        newState[action.level] = action.id;
        newState.length = action.level + 1;
        saveState('selectedAnswers', newState);
      }
      return newState;
    case 'RETURN_TO':
      let indexOfReturnTo = newState.indexOf(action.id);
      newState.length = indexOfReturnTo + 1;
      saveState('selectedAnswers', newState);
      return newState;
    case 'CLEAR_ANSWERS':
      saveState('selectedAnswers', []);
      return [];
    case 'RESTART_AT':
      saveState('selectedAnswers', [action.id]);
      return [action.id];
    default:
      return state;
  }
}

export default selectedAnswers;
