import isArray from 'lodash/isArray';

function modal(state = {}, action) {

	let newState = {...state}

	switch (action.type) {
		case "TOGGLE_MODAL":
			if(!newState.open && (!isArray(newState.modalsDisplayed) || !newState.modalsDisplayed.includes(action.payload.modal)) ) {
				newState.open = true
				newState.selected = action.payload.modal
				newState.requireInteraction = action.payload.requireInteraction

				if (action.payload.once) {
					if (!isArray(newState.modalsDisplayed))
						newState.modalsDisplayed = []

					newState.modalsDisplayed.push(action.payload.modal)
				}
			}
			
			if(state.open) {
				newState.open = false
				newState.selected = ''
			}
			
			return newState;
		default:
			return newState;
	}
}

export default modal;
