import React from 'react';
import Img from 'images/modal/estimated-survival.jpg'

const EstimatedSurvival = (props) => (
  <div>
    <h1>Estimated Emergence</h1>
    { /* eslint-disable-next-line react/jsx-no-target-blank */ }
    <p>Emergence is the number of live plants expressed as a percentage of the number of seeds placed in the ground at around the 2-4 leaf stage. Field emergence is always lower than the germination of a sample because germination tests are done in a lab under ideal conditions. Some of the factors that affect emergence include soil temperature, soil moisture, seed placement (depth, proximity to other seeds), and the proximity to and quantity of fertilizer. Emergence can be improved by seeding into optimum seedbed conditions, performing regular equipment maintenance and calibration and by decreasing seeding speed. <a href="http://www.canolacouncil.org/canola-encyclopedia/crop-establishment/" target="_blank">For more information</a></p>
    <img src={Img} alt="Estimated Survival"/>
  </div>
);

export default EstimatedSurvival;
