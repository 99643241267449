import React from 'react';
import Img from 'images/modal/flea-beetle.jpg'

const InsectDamage = (props) => (
  <div>
    <h1>In-season insect damage</h1>
    { /* eslint-disable-next-line react/jsx-no-target-blank */ }
    <p>Given that flea beetle populations are fixed based on the number of overwintering adults, spreading that population over more plants per unit area generally means less damage per seedling. With less damage per seedling, more plants are likely to survive flea beetle feeding. This prevents or limits delayed maturity that can result from heavy feeding, and it also reduces the need for in-crop foliar insecticide applications. Increased plant densities may also reduce damage by other insect pests, including root maggots. Cabbage seedpod weevils are mostly restricted to southern Alberta and their threshold is based on an insecticide application at 20-30% bloom. Thin stands will tend to have a broader range of maturity, making it harder to spray within that narrow window than a thicker, more uniformly maturing crop. <a href="http://www.canolacouncil.org/canola-encyclopedia/crop-establishment/" target="_blank">For more information</a></p>
    <img src={Img} alt="In-season insect damage"/>
  </div>
);

export default InsectDamage;
