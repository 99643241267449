function error(state = null, action) {
  switch (action.type) {
    case 'SAVING_ERROR':
      return { message: action.message };
    
    case 'CHANGE_FIELD_NAME':
      return null;

    default:
      return state;
  }
}

export default error;