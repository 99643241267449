import React from 'react';

const SeedLossModal = (props) => (
	<div>
		<h1>Seed Loss</h1>
		<p>For an accurate seed loss measurement, remember to disengage your chopper and spreaders and to reposition them so they are out of the way and so the discharge can drop straight down. Also ensure the combine is being driven at the typical operating speed when dropping the collection pan to take a sample.</p>
	</div>
);

export default SeedLossModal;
