import React, { useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from 'components/shared/Button'
import usePWA from 'react-pwa-install-prompt'
import { iOS } from 'helpers';
import config from 'config';

const animationDuration = 180; // milliseconds

const slidePromptIn = keyframes`
	0% {
		transform: translateY(-50px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
`;

const slidePromptOut = keyframes`
	0% {
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		transform: translateY(-50px);
		opacity: 0;
	}
`;

const InstallPrompt = styled.div`
	position: fixed;
	top: 0;
	min-height: 3em;
	width: 100%;
	z-index: 1000;

	text-align: center;
	padding: 0 3.4rem 10px; /* 3.4 is 1 + the text size of the close button */
	background-color: ${config.colors.darkGrey};
	color: #fff;
	box-shadow: 1px 0 10px 0px rgba(0,0,0,0.4);

	animation-duration: ${animationDuration}ms;
	animation-timing-function: ease;
	animation-play-state: paused;
	animation-fill-mode: both;

	display: none;
	transform: translateY(-50px);
	opacity: 0;

	&.displaying {
		animation-name: ${slidePromptIn};
		animation-play-state: running;
		animation-delay: 2.5s;
	}

	&.dismissed {
		animation-name: ${slidePromptOut};
		animation-play-state: running;
	}
`;

const InstallButton = styled.button`
	margin-top: 0 !important;
`;

const CloseButton = styled.button`
	position: absolute;
	top: 5px;
	right: 5px;
	font-size: 2.4rem;
	padding: 0px 0.7rem;
	background-color: transparent;
	color: ${config.colors.orange};
`;

const PWAInstallPrompt = (props) => {
	const { isStandalone, isInstallPromptSupported, promptInstall } = usePWA()

	const [ wasInstallPrompted, setWasInstallPrompted ] = useState( localStorage.getItem('wasInstallPrompted') ?? false );
	const [ showPrompt, setShowPrompt ] = useState(false);

	let	promptInner,
	promptTimeout = 7500;

	let promptRef = useRef(null);

	const onClickInstall = async () => {
		const didInstall = await promptInstall()
		hidePrompt();

		if (didInstall) {
			console.log('PWA Installed')
		}
	}
	
	const onHidePrompt = () => {
		hidePrompt();
	}

	const hidePrompt = () => {
		// Set local component's state
		setWasInstallPrompted(true);

		// Update state triggers hiding the prompt in the DOM,
		// so it must happen after the animation to visually hide has completed
		window.setTimeout( () => { setShowPrompt(false) }, animationDuration)

		// Set localStorage to persist on reload
		localStorage.setItem('wasInstallPrompted', true);

		// Trigger animation out
		promptRef.current.classList.add('dismissed');
		promptRef.current.classList.remove('displaying');
	}

	// Don't prompt a second time, or in a standalone view (already in an app)
	if (!showPrompt && !wasInstallPrompted && !isStandalone) {
		setShowPrompt(true);
	}

	if (showPrompt && (iOS() || isInstallPromptSupported)) {
		window.setTimeout( hidePrompt, promptTimeout )
	}
	
	/**
	 * In order to animate in and out, the component will stay mounted 
	 * in the DOM. Visiblity is controlled via style (class and attr).
	 * 
	 * The inner content is changed based on iOS (no PWA) or otherwise (Android).
	 */
	
	// As of iOS 16, beforeinstallprompt event and PWAs are not supported,
	// so trigger a manual prompt.
	if (iOS()) {
		promptInner = <React.Fragment>
			<h2 style={ {fontSize: "1em", marginBottom: '0.2em' } }>Add to Home Screeen</h2>
			<p style={ {margin: '0' } }>Use the share button to add the Canola Calculator Web App to your home screen!</p>
		</React.Fragment>
		
	} else if (isInstallPromptSupported) { // PWA standard prompt (beforeinstallprompt event has fired)
		promptInner = <React.Fragment>
			<p>Install the Canola Calculator Web App to your device!</p>
			<InstallButton as={ Button } className="small" onClick={onClickInstall}>
				Install Now
			</InstallButton>
		</React.Fragment>
	} else {
		return null; // platforms that don't support PWA - eg: FF on macOS
	}
	
	return (
		<InstallPrompt ref={ promptRef }
			className={ showPrompt ? 'displaying' : '' } 
			style={ showPrompt ? { display: 'block' } : { display: 'none' } } // must be separate from class for animation out
		>
			{ promptInner }
			
			<CloseButton onClick={onHidePrompt}>
				<span aria-hidden="true">&times;</span>
				<span className="sr-only">Close</span>
			</CloseButton>
		</InstallPrompt>
	);
}

export default PWAInstallPrompt;