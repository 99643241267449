import React from 'react';

const CombineInfo5 = (props) => (
  <div>
    <p>To determine source of loss:</p>
    <ol>
      <li>Disengage spreader/chopper as required to drop all residue in a windrow.</li>
      <li>Harvest at a steady state where at least 1 to 2 bu/ac loss is occurring.</li>
      <li>Catch a sample of the residue coming from the cleaning system (lower/forward of the residue discharge). HINT: A scoop shovel  works well, Shake briefly, and assess.</li>
      <li>Repeat #3 for residue from rotor/separator (upper/rearward of the residue discharge).</li>
      <li>The catch with more seeds present is the primary source of loss.</li>
    </ol>
  </div>
);

export default CombineInfo5;
