import { initialCanolaCountsState, initialFieldState, initialTownshipState } from 'store'

import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';


function canolaCounts(state = {}, action) {
	let newState = {
		...state
	}

	switch (action.type) {
		case "CANOLACOUNTS/SET":
			// Remove duplicate validation errors, specifically for required radio fields.
			if (action.payload.name === 'validationErrors') {
				action.payload.value = uniqWith(action.payload.value, isEqual)
			}

			newState[action.payload.name] = action.payload.value

			return newState;
		case "CANOLACOUNTS/ADDFIELD":
			newState.fields[newState.fields.length] = {...initialFieldState};
			
			return newState
		case "CANOLACOUNTS/REMOVEFIELD":
			if (Array.isArray(newState.fields) && newState.fields.length >= 1)
				newState.fields.pop()

			return newState

		// LocalStoragte actions
		case "CANOLACOUNTS/UPDATEFIELDPROPERTY":
			newState.fields[action.payload.fieldIndex][action.payload.property] = action.payload.value;
			return newState

		case "CANOLACOUNTS/LOADSTATE":	
			try {
				const serializedState = localStorage.getItem('canolaCounts');

				if (serializedState === null) {
					throw new Error('No saved state found')
				}
				newState = JSON.parse(serializedState);				
			} catch (err) {
				console.error(`Error getting CanolaCounts state from local storage. ${err}`)
				return false;
			}
			
			return newState
		
		case "CANOLACOUNTS/SAVESTATE":
			const now = new Date()
			
			try {
				let stateToSave = {
					name: state.name,
					email: state.email,
					fields: state.fields,
					province: state.province,
					subscribeToUpdates: state.subscribeToUpdates,
					enterPrize: state.enterPrize,
					stateLastSaved: now,
				}

				const serializedState = JSON.stringify(stateToSave);
				newState.stateLastSaved = now

				localStorage.setItem('canolaCounts', serializedState);
			} catch (err) {
				console.error(`Error saving CanolaCounts state to local storage. ${err}`)
			}

			return newState
		
		case "CANOLACOUNTS/RESETSURVEY":
			try {
				localStorage.removeItem('canolaCounts');
			} catch (e) {
				console.warn(`Error removing local storage: ${e}`)
			}

			return { 
				...initialCanolaCountsState,
				fields: [{...initialFieldState}],
			}				
		default:
			return state;
	}
}

export default canolaCounts;
