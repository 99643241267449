import styled, { keyframes } from 'styled-components';

import Button from './shared/Button'

const fade = keyframes`
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
`

const LoadingContainer = styled.div`
	text-align: center;
	
	.loading-text {
		margin-top: 3em;
		animation-name: ${fade};
		animation-duration: 1s;
		animation-timing-function: cubic-bezier(0.95,0,0,0);
		animation-direction: alternate;
		animation-iteration-count: infinite;
	}
`

export default function Loading (props) {
	if (props.error) {
		return <LoadingContainer>
			<h2 className="loading-text">Error loading.</h2>
			<Button style={ {marginTop: 0} } className="small" onClick={ props.retry }>Retry</Button>
		</LoadingContainer>;
	} else if (props.timedOut) {
		return <LoadingContainer>
			<h2 className="loading-text">Still loading...</h2>
			<Button style={ {marginTop: 0} } className="small" onClick={ props.retry }>Retry</Button>
		</LoadingContainer>;
	} else if (props.pastDelay) {
		return <LoadingContainer>
			<h2 className="loading-text">Loading...</h2>
		</LoadingContainer>
	} else {
		return null;
	}
}