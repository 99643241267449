// Density is in lb/bu, TKW is in g/1000 kernels
// Using Map gives us the best of arrays and objects; getting by a string-based key, and iterable. 
// @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
const crops = new Map([
	['Alfalfa', { 'density': 60, 'tkw': 2.27 } ],
	['Barley - 2 row', { 'density': 48, 'tkw': 45 } ],
	['Barley - 6 row', { 'density': 48, 'tkw': 37.5 } ],
	['Bromegrass (meadow)', { 'density': 14, 'tkw': 5.22 } ],
	['Bromegrass (smooth)', { 'density': 14, 'tkw': 3.17 } ],
	['Buckwheat', { 'density': 48, 'tkw': 30 } ],
	['Canary seed', { 'density': 50, 'tkw': 6.5 } ],
	['Canola', { 'density': 50, 'tkw': 4.83 } ],
	['Corn', { 'density': 56, 'tkw': 380 } ],
	['Dry bean', { 'density': 60, 'tkw': 195 } ],
	['Faba bean', { 'density': 60, 'tkw': 387.5 } ],
	['Fescue (creeping red)', { 'density': 21.5, 'tkw': 1.2 } ],
	['Fescue (meadow)', { 'density': 21.5, 'tkw': 1.97 } ],
	['Fescue (tall)', { 'density': 21.5, 'tkw': 2.2 } ],
	['Flaxseed', { 'density': 56, 'tkw': 5.75 } ],
	['Lentil', { 'density': 60, 'tkw': 55 } ],
	['Mustard', { 'density': 50, 'tkw': 5.75 } ],
	['Oat', { 'density': 32, 'tkw': 37.5 } ],
	['Pea', { 'density': 60, 'tkw': 212.5 } ],
	['Rye', { 'density': 56, 'tkw': 36.6 } ],
	['Ryegrass', { 'density': 23.5, 'tkw': 2 } ],
	['Soybean', { 'density': 60, 'tkw': 150 } ],
	['Sunflower (confection)', { 'density': 32, 'tkw': 175 } ],
	['Sunflower (oil)', { 'density': 32, 'tkw': 126 } ],
	['Timothy seed', { 'density': 45, 'tkw': 0.39 } ],
	['Wheat', { 'density': 60, 'tkw': 43 } ],
	['Wheatgrass (diploid)', { 'density': 22, 'tkw': 1.46 } ],
	['Wheatgrass (tretraploid)', { 'density': 22, 'tkw': 5.15 } ],
]);

// Output of the below functions must return an imperial unit
//
// dischargeWidth / 12 = inch to feet
const formulas = {
	weight: {
		pounds: (state) => ( (state.seedLoss * (state.dischargeWidth / 12) * 96) / (state.collectingArea * state.cutWidth) ),
		bushels: (state) => ( (state.seedLoss * (state.dischargeWidth / 12) * 96) / (state.collectingArea * state.cutWidth * crops.get(state.crop).density) ),
		percent: (state) => ( (state.seedLoss * (state.dischargeWidth / 12) * 9600) / (state.collectingArea * state.cutWidth * crops.get(state.crop).density * state.expectedYield) ) / 100,
		dollars: (state) => ( (state.seedLoss * (state.dischargeWidth / 12) * state.price * 96) / (state.collectingArea * state.cutWidth * crops.get(state.crop).density) ),
	},
	volume: {
		pounds: (state) => ( (state.seedLoss * (state.dischargeWidth / 12) * crops.get(state.crop).density * 1.2) / (state.collectingArea * state.cutWidth) ),
		bushels: (state) => ( (state.seedLoss * (state.dischargeWidth / 12) * 1.2) / (state.collectingArea * state.cutWidth) ),
		percent: (state) => ( (state.seedLoss * (state.dischargeWidth / 12) * 120) / (state.collectingArea * state.cutWidth * state.expectedYield) ) / 100,
		dollars: (state) => ( (state.seedLoss * (state.dischargeWidth / 12) * state.price * 1.2) / (state.collectingArea * state.cutWidth) ),
	},
	count: {
		pounds: (state) => ( (state.seedLoss * (state.dischargeWidth / 12) * crops.get(state.crop).tkw * 0.096) / (state.collectingArea * state.cutWidth) ),
		bushels: (state) => ( (state.seedLoss * (state.dischargeWidth / 12) * crops.get(state.crop).tkw * 0.096) / (state.collectingArea * state.cutWidth * crops.get(state.crop).density) ),
		percent: (state) => ( (state.seedLoss * (state.dischargeWidth / 12) * crops.get(state.crop).tkw * 9.6) / (state.collectingArea * state.cutWidth * crops.get(state.crop).density * state.expectedYield) ) / 100,
		dollars: (state) => ( (state.seedLoss * (state.dischargeWidth / 12) * crops.get(state.crop).tkw * state.price * 0.096) / (state.collectingArea * state.cutWidth * crops.get(state.crop).density) ),
	},
}

const seedLossUnits = new Map([
	['weight', 'g'],
	['volume', 'mL'],
	['count', 'n'],
])

export { crops, formulas, seedLossUnits }