import React from 'react';

const CombineInfo1 = (props) => (
  <div>
    <h1>Rethresher</h1>
    <p>Returns system has a rethresher and delivers the rethreshed material to the cleaning system.</p>
  </div>
);

export default CombineInfo1;
