import React from 'react';

const CombineInfo2 = (props) => (
  <div>
    <h1>No Rethresher</h1>
    <p>Returns system has no rethresher and delivers the tailings material back to the threshing system.</p>
  </div>
);

export default CombineInfo2;
