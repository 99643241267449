const set = (payload) => {
	return {
		type: "CANOLACOUNTS/SET",
		payload: payload,
	}
}

const addField = () => {
	return {
		type: "CANOLACOUNTS/ADDFIELD"
	}
}

const removeField = () => {
	return {
		type: "CANOLACOUNTS/REMOVEFIELD"
	}
}

const updateFieldProperty = (fieldIndex, property, value) => {
	return {
		type: "CANOLACOUNTS/UPDATEFIELDPROPERTY",
		payload: {
			fieldIndex: fieldIndex,
			property: property,
			value: value,
		}
	}
}

const loadStateFromLocalstorage = () => {
	return {
		type: "CANOLACOUNTS/LOADSTATE"
	}
}

const saveStateToLocalstorage = () => {
	return {
		type: "CANOLACOUNTS/SAVESTATE"
	}
}

const resetSurvey = () => {
	return {
		type: "CANOLACOUNTS/RESETSURVEY"
	}
}

export { set, removeField, addField, updateFieldProperty, loadStateFromLocalstorage, saveStateToLocalstorage, resetSurvey }