import { React, useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import { preloadRemoteHomepage, loadRemoteHomepageCache, hasHomepageCache } from './wordpressHomepageLoader';


import Loadable from 'react-loadable';
import Loading from './components/Loading'

import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import Sidebar from './components/Sidebar';
import Nav from './components/Nav';
import Modal from './components/Modal';
import Footer from './components/Footer';

import AnalyticsTracker from 'components/AnalyticsTracker';

// Adding Cookies to the page
import CookieConsent from 'react-cookie-consent';

import store from './store';

// These are for mapping stuff to props
import { toggleSidebar } from './actions/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PWAInstalPrompt from './components/shared/PWAInstallPrompt';

import { Helmet } from "react-helmet"
import config from "config"
const { title, description } = config.meta.defaults


// Loadable routes
const loadableConfig = {
  loading: Loading,
  timeout: 10000
}

const LoadableMenu = Loadable({
  loader: () => import('./components/Menu'),
  ...loadableConfig
});

const LoadableTargetPlantDensity = Loadable({
  loader: () => import('./components/TargetPlantDensity'),
  ...loadableConfig
});

const LoadableHarvestLoss = Loadable({
  loader: () => import('./components/HarvestLoss/HarvestLoss'),
  ...loadableConfig
});

const LoadableCanolaCounts = Loadable({
  loader: () => import('./components/CanolaCounts/CanolaCounts'),
  ...loadableConfig
});

const LoadableBlacklegLoss = Loadable({
  loader: () => import('./components/BlacklegLoss/BlacklegLoss'),
  ...loadableConfig
});

const LoadableSeedingRateParent = Loadable({
  loader: () => import('./components/SeedingRateParent'),
  ...loadableConfig
});

const LoadableMaximumYieldDiagnostic = Loadable({
  loader: () => import('./components/MaximumYieldDiagnostic'),
  ...loadableConfig
});


const App = (props) => {
  // Renders on each component change, triggering an event
  // https://stackoverflow.com/a/63249329
  AnalyticsTracker();


  const location = useLocation();

  let pathname;
  if (!isNil(location.pathname)) {
    pathname = location.pathname
  } else {
    console.log('location.pathname is null')
    pathname = window.location.pathname
  }
  const urlSegments = pathname.split('/');

  const lastRoute = urlSegments[urlSegments.length - 1]
  const lastRouteCamel = camelCase(lastRoute)
  const pageTitle = get(config.meta, lastRouteCamel + '.title', config.meta.defaults.title);
  const pathClassName = isNil(lastRoute) || isEmpty(lastRoute) ? 'home' : lastRoute;




  let classNamesArr = [];
  if (props.sidebar) {
    classNamesArr.push('sidebar-open');
  }
  if (props.modal) {
    classNamesArr.push('modal-open');
  }
  let classNamesStr = classNamesArr.join(' ');

  // Trigger actions on location (route) change.
  useEffect(() => {
    
    const params = new URLSearchParams(location.search)

    // Loading state from email when the query string exists.
    if (params.get('v')) {
      store.dispatch({
        type: 'LOAD_FROM_QUERY_STRING',
        location: location,
        params: params
      })
    }

    // When query string has 'metric' update state;
    // This is not used in tools that have specific metric toggle, eg: harvest loss.
    if (params.get('metric')) {
      store.dispatch({
        type: 'TOGGLE_USE_METRIC',
        metric: parseInt(params.get('metric'))
      })
    }

    // Reset emailing and saving (may no longer be used) states
    store.dispatch({ type: 'RESET_EMAILING_STATE' })

    // Close all accordions on route change
    store.dispatch({ type: 'CLOSE_ACCORDIONS' })

    // Close result pane on route change
    store.dispatch({ type: 'HIDE_RESULT' })

    // Close sidebar menu on route change
    store.dispatch({ type: 'HIDE_SIDEBAR' })
  }, [location]);

  // Preload remote Wordpress homepage
  preloadRemoteHomepage();

  const isHomepageCached = hasHomepageCache();
  // If we are on the homepage and we have the remote homepage cached, load it
  console.log("Currently on " + pathname);
  console.log("We have homepage cache: " + (isHomepageCached) ? "Yes" : "No");

  if (( pathname === '/calculator/' || pathname === '/' || pathname === '/calculator') && isHomepageCached) {
    console.log("Should only load when loading not from cache")
    loadRemoteHomepageCache();
    return;
  }

  return (
    <div id="page" className={classNamesStr}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>

      <Sidebar />
      <main id="main" className={pathClassName}>
        <Nav title={pageTitle.replace(/\s\|\s.*/, '')} />

        <section id="content">
          {/* Use Switch to only render the first Route or Redirect that matches the location */}
          <Switch>
            <Route exact path={process.env.PUBLIC_URL + '/'} component={LoadableMenu} />
            <Route path={process.env.PUBLIC_URL + '/target-plant-density'} component={LoadableTargetPlantDensity} />
            <Route path={process.env.PUBLIC_URL + '/seeding-rate'} component={LoadableSeedingRateParent} />
            <Route path={process.env.PUBLIC_URL + '/canola-counts'} component={LoadableCanolaCounts} />
            <Route path={process.env.PUBLIC_URL + '/harvest-loss'} component={LoadableHarvestLoss} />
            <Route path={process.env.PUBLIC_URL + '/blackleg-loss'} component={LoadableBlacklegLoss} />
            <Route path={process.env.PUBLIC_URL + '/combine-optimization'} component={LoadableMaximumYieldDiagnostic} />
          </Switch>
        </section>

        <div
          className="active-sidebar-overlay"
          onClick={() => {
            props.actions.toggleSidebar();
          }}
        />
      </main>
      <Footer location={props.location} />

      <Modal />

      <CookieConsent
        buttonText="Dismiss"
        expire="30"
        disableStyles={true}
        containerClasses="cookie-bar"
        contentClasses="cookie-content"
        buttonClasses="cookie-dismiss"
      >
        <p>Privacy & Cookies: This site uses cookies. By continuing to use this website, you agree to their use. To find out more, including how to control cookies, see here: <a href="/privacy-policy/" target="_blank">Privacy policy</a></p>
      </CookieConsent>

      <PWAInstalPrompt />
    </div>
  );
}

const stateToProps = (state) => {
  return {
    sidebar: state.sidebar,
    modal: state.modal.open
  };
}

const dispatchToProps = (dispatch) => {
  return { actions: bindActionCreators({ toggleSidebar }, dispatch) };
}

export default connect(stateToProps, dispatchToProps)(App);
