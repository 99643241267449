import React from 'react';
import Img from 'images/modal/looking-for-seeds.jpg'

const TargetPlantDensity = (props) => (
  <div>
    <h1>Target plant density</h1>
    { /* eslint-disable-next-line react/jsx-no-target-blank */ }
    <p>The CCC recommends targeting at least 5-8 plants/ft<sup>2</sup>. Above that, you are buying extra insurance against in-season stressors and below that you are starting to take on more and more production risk. Below around 4 plants/ft<sup>2</sup> we start to see yield potential drop off dramatically as there isn't any buffer to protect your stand from insects, environmental damage etc. Fields with thin stands will have to be managed with extra intensity and will require more regular scouting for insects and disease. Weed control will have to be timely and effective, and these thin stands will often mature later and need more thorough assessment to assess harvest timing. <a href="http://www.canolacouncil.org/canola-encyclopedia/crop-establishment/" target="_blank">For more information</a></p>
    <img src={Img} alt="Target plant density"/>
  </div>
);

export default TargetPlantDensity;
