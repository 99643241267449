import React from 'react';
import PropTypes from 'prop-types';

import styled, { keyframes } from 'styled-components';
import isString from 'lodash/isString';
import isNull from 'lodash/isNull';


import config from 'config'
import { hexToRgbString } from 'helpers'

import { ReactComponent as IconLoading } from 'images/icon-loading.svg';


const rotateIcon = keyframes`
	0% {
		transform: rotateZ(0deg)
	}
	100% {
		transform: rotateZ(360deg)
	}
`

const pulseBackgroundPrimary = keyframes`
	0% { background-color: rgba(${hexToRgbString(config.colors.orange)}, 0.9); }
	100% { background-color: rgba(${hexToRgbString(config.colors.orange)}, 0.15); }
`

const ButtonElement = styled.button`
	margin-top: 20px;
	padding: 20px 20px;
	border-radius: 8px;
	font-weight: 600;
	font-size: 0.9rem;
	
	min-width: 100%;
	max-width: 23em;
	min-height: 5.3em;

	transition: color 0.18s ease,
		background-color 0.18s ease,
		border-color 0.18s ease;

	animation: 1s ease-out alternate-reverse none infinite;
	
	img, svg {
		margin-left: auto;
		margin-right: 0;
		max-width: 30px;
		width: 100%;
	
		animation: 3s linear 0s infinite none;
	}

	* {
		transition: fill 0.18s ease,
			stroke 0.18s ease;
	}

	&.primary {
		background-color: ${config.colors.orange};
		border: 2px solid ${config.colors.orange};
		text-align: center;
		padding: 10px 20px;
		margin: 30px auto 0;

		&:hover {
			background-color: #fff;
		}
	}

	&.secondary {
		border: 2px solid ${config.colors.green};
		color: ${config.colors.green};
		background-color: transparent;

		&:hover {
			background-color: ${config.colors.green};
			color: #fff;
		}
	}

	&.caution {
		text-transform: uppercase;
		letter-spacing: 0.03em;
		padding: 7px 20px;
		color: ${config.colors.red};
		border: 2px solid ${config.colors.red};
		background-color: #e1e1e1;
		min-width: 15em;
		min-height: 0;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	
		&:hover {
			background-color: ${config.colors.red};
			color: #fff;
		}
	}

	&.isLoading{
		&.primary {
			animation-name: ${pulseBackgroundPrimary};
		}

		img, svg {
			animation-name: ${rotateIcon};
		}
	}

	&[disabled] {
		cursor: not-allowed;
		background-color: #d7d7d7 !important;
		border-color: #aaa!important;
		color: #999!important;

		* { opacity: 0.6 }

		&:hover {
			background-color: #d7d7d7!important;
		}
	}

	&.small {
		min-width: 15em;
		min-height: 0;

		&.caution {
			min-width: 10em;
		}
	}

	&.centered {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	&.has-icon {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		text-align: left;
	}
`

class Button extends React.Component {

	static propTypes = {
		isLoading: PropTypes.bool,
		type: PropTypes.string,
		buttonStyle: PropTypes.oneOf(['primary', 'secondary', 'caution'])
	}

	static defaultProps = {
		isLoading: false,
		type: 'button',
		buttonStyle: 'primary',
	}

	/* eslint-disable-next-line no-useless-constructor */
	constructor(props) {
		super(props)
	}

	render () {
		let icon = !isNull(this.props.icon) && isString(this.props.icon) ? 
			<img src={this.props.icon} alt="" /> : 
			this.props.icon
		
		let classes = []
		classes.push(this.props.buttonStyle)
		classes.push(this.props.className)

		if (typeof icon !== 'undefined' && icon !== '') classes.push('has-icon')

		if (this.props.isLoading) {
			classes.push('isLoading')
			classes.push('has-icon')
		}

		return (
			<ButtonElement {...this.props} className={classes.join(' ')} type={ this.props.type }>
				{ this.props.children }
				{ this.props.isLoading ? <IconLoading /> : icon }
			</ButtonElement>
		);
	}
}

export default Button