import React from 'react';

const CollectingAreaModal = (props) => (
	<div>
		<h1>Collecting Area</h1>
		<p>This could be a harvest drop pan or any kind of collection pan (found around the farm on at a hardware store), preferably rectangular to make it easy (and accurate) to calculate the collecting area. When using smaller collection pans, take multiple measurements across the width of the discharge span (for improved accuracy). Larger collection pans provide less indication of the location of seed losses (across the discharge width), but can reduce sampling error.</p>
	</div>
);

export default CollectingAreaModal;
