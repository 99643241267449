import React from 'react';
import Img1 from 'images/modal/seed-tag.jpg'
import Img2 from 'images/modal/TKW.jpg'

const EstimatedSurvival = (props) => (
  <div>
    <h1>T.S.W.</h1>
    { /* eslint-disable-next-line react/jsx-no-target-blank */ }
    <p>Above around 3.5 grams per thousand seeds, increased seed size has not been proven to increase germination or yield, and a disadvantage of heavier seeds is that more lbs of seed are required to achieve the same plant population versus lighter seeds. Recent research suggests that larger seed may increase early season biomass, may decrease susceptibility to flea beetle damage, and may shorten days to the start and end of flowering (Harker et all, 2015). <a href="http://www.canolacouncil.org/canola-encyclopedia/crop-establishment/" target="_blank">For more information</a></p>
    <p className="centred"><img src={Img1} alt="T.S.W."/></p>
    <p className="centred"><img src={Img2} alt="TKW"/></p>
  </div>
);

export default EstimatedSurvival;
