import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';

import 'rc-slider/assets/index.css';
import './styles/main.scss';

import { Provider } from 'react-redux';

import store from './store';
import history from './history';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

ReactDOM.render(
  <Provider store={ store }>
    <Router history={ history }>
      <App/>
    </Router>
  </Provider>,
  document.getElementById('root')
);