import history from '../history';

import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';

export function toggleSidebar() {
  return {
    type: "TOGGLE_SIDEBAR"
  };
}

export function openAccordion(name) {
  return {
    type: "OPEN_ACCORDION",
    name
  };
}

export function changeField(name, value, result='') {
  return {
    type: "CHANGE_FIELD",
    name,
    value,
    result
  };
}

export function showResult() {
  return { type: "SHOW_RESULT" };
}

export function onSeedCostChange(val) {
  return { type: "SEED_COST_CHANGED", payload: val };
}

export function hideResult() {
  return { type: "HIDE_RESULT" };
}

export function handleEmailChange(value) {
  return {
    type: "HANDLE_EMAIL_CHANGE",
    value
  }
}

// useHistory defaults to true for legacy reasons; false is used in CanolaCounts
// 'once' will ensnure that a specific modal is only displayed once per session,
// by storing it in a list of displayed modals in the Store.
export function toggleModal(modal='', opts = {} ) {

	let defaults = {
		useHistory: true,
		once: false,
		requireInteraction: false,
	}

	opts = {...defaults, ...opts}

	if (opts.useHistory && isString(modal) && modal !== '') {
		history.push({pathname: history.location.pathname, hash: `#${modal}`})
  } else if (opts.useHistory && (!isString(modal) || isEmpty(modal))) {
		history.push({pathname: history.location.pathname, hash: ``})
  }

	opts.modal = modal

	return {
		type: "TOGGLE_MODAL",
		payload: { ...opts }
	}
}

export function showEmailField() {
  return {
    type: "SHOW_EMAIL_FIELD"
  }
}

export function sendEmail(email, fields, pathname, metric) {
  return {
    type: "SEND_EMAIL",
    email,
    fields,
    pathname,
    metric
  }
}

export function togglePlot() {
  return {
    type: "TOGGLE_PLOT"
  }
}

export function addAnswer(id, level) {
  return {
    type: 'ADD_ANSWER',
    id,
    level
  }
}

export function returnTo(id) {
  return {
    type: 'RETURN_TO',
    id
  }
}
export function clearAnswers() {
  return {
    type: 'CLEAR_ANSWERS'
  }
}
export function restartAt(id) {
  return {
    type: 'RESTART_AT',
    id
  }
}

export function toggleBeforeYouStartAccordionOpen() {
  return {
    type: "TOGGLE_BEFORE_YOU_START_ACCORDION_OPEN"
  }
}
