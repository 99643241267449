function metric(state=false, action) {
  switch (action.type) {
    case 'TOGGLE_PLOT':
      return !state;

    case 'TOGGLE_USE_METRIC':
      return (action.metric === 1 ? true : state);

    default:
      return state;
  }
}

export default metric;
