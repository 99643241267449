import React from 'react';
import { useEffect, useRef } from "react";
import { useHistory } from 'react-router-dom';

// These are for mapping stuff to props
import { toggleModal } from '../actions/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import FrostRisk from '../info/FrostRisk';
import GrowingSeason from '../info/GrowingSeason';
import InsectDamage from '../info/InsectDamage';
import WeedControl from '../info/WeedControl';
import StandUniformity from '../info/StandUniformity';

import TSW from '../info/TSW';
import TargetPlantDensity from '../info/TargetPlantDensity';
import EstimatedSurvival from '../info/EstimatedSurvival';
import ActualPlantDensity from '../info/ActualPlantDensity';
import SeedingRate from '../info/SeedingRate';
import DesiredSeedingRate from '../info/DesiredSeedingRate';

import CombineInfo1 from '../info/combine-info-1';
import CombineInfo2 from '../info/combine-info-2';
import CombineInfo3 from '../info/combine-info-3';
import CombineInfo4 from '../info/combine-info-4';
import CombineInfo5 from '../info/combine-info-5';
import CombineInfo6 from '../info/combine-info-6';

// Havest Loss modals
import CollectingAreaModal from './HarvestLoss/Modals/CollectingAreaModal';
import SeedLossModal from './HarvestLoss/Modals/SeedLossModal';
import DischargeWidth from './HarvestLoss/Modals/DischargeWidth';

// Canola Counts
import Welcome from './CanolaCounts/Modals/Welcome.js';
import RestoreState from './CanolaCounts/Modals/RestoreState.js';

// Blackleg Loss
import BlacklegSeverityRating from './BlacklegLoss/Modals/BlacklegSeverityRating';
import BlacklegIncidencePercent from './BlacklegLoss/Modals/BlacklegIncidencePercent';


// Use a Map to simplify logic
let modals = new Map();

modals.set( 'frostRisk', FrostRisk );
modals.set( 'growingSeason', GrowingSeason );
modals.set( 'insectDamage', InsectDamage );
modals.set( 'weedControl', WeedControl );
modals.set( 'standUniformity', StandUniformity );

modals.set( 'tsw', TSW );
modals.set( 'targetPlantDensity', TargetPlantDensity );
modals.set( 'estimatedSurvival', EstimatedSurvival );
modals.set( 'density', ActualPlantDensity );
modals.set( 'seedingRate', SeedingRate );
modals.set( 'desiredSeedingRate', DesiredSeedingRate );

modals.set( 'combine-info-1', CombineInfo1 );
modals.set( 'combine-info-2', CombineInfo2 );
modals.set( 'combine-info-3', CombineInfo3 );
modals.set( 'combine-info-4', CombineInfo4 );
modals.set( 'combine-info-5', CombineInfo5 );
modals.set( 'combine-info-6', CombineInfo6 );

// Havest Loss modals
modals.set( 'collectingArea', CollectingAreaModal );
modals.set( 'seedLoss', SeedLossModal );
modals.set( 'dischargeWidth', DischargeWidth );

// Canola Counts
modals.set( 'canolaCountsWelcome', Welcome );
modals.set( 'restoreState', RestoreState );

// Blackleg Loss
modals.set( 'severityRating', BlacklegSeverityRating );
modals.set( 'incidencePercent', BlacklegIncidencePercent );


const Modal = (props) => {
  let component;
  let history = useHistory();

  // is first run
  let firstRunRef = useRef(true);

  // Restore modal on page load if we have a location hash
  useEffect(() => {
    let hash = window.location.hash.substring(1);

    // Only run effect on first run; eg componentDidMount
    if (firstRunRef.current) {
      if (hash !== '' && modals.has(hash)) {
        props.actions.toggleModal(hash)
      }
    }

    // Stops effect from running again
    firstRunRef.current = false;
  })

  if (modals.has(props.selected)) {
    component = React.createElement( modals.get(props.selected) );
  } else {
    return <React.Fragment/>
  }

  let backgroundOnClick = props.useHistory ? history.goBack : props.actions.toggleModal
  if (props.requireInteraction === true) backgroundOnClick = null

  return (
    <div className="modal">
      <div className="modal-fade-screen" onClick={ backgroundOnClick }>
        <div className={`modal-inner modal-${props.selected}`} onClick={(e) => { e.stopPropagation(); }}>
          { !props.requireInteraction && <div className="modal-close" onClick={ props.useHistory ? history.goBack : props.actions.toggleModal }></div> }
          {component}
        </div>
      </div>
    </div>
  );
}

const stateToProps = (state) => {
  return { ...state.modal };
}

const dispatchToProps = (dispatch) => {
  return { actions: bindActionCreators({ toggleModal }, dispatch) };
}

export default connect(stateToProps, dispatchToProps)(Modal);
