import React from 'react';
import Img from 'images/modal/uniform-emergence.jpg'

const StandUniformity = (props) => (
  <div>
    <h1>Stand uniformity</h1>
    { /* eslint-disable-next-line react/jsx-no-target-blank */ }
    <p>Non-uniform plant stands can be caused by poor seeding conditions (too wet, too cold, too much residue, poorly distributed residue), poor seed placement (seeding too fast, seeding into soil that is too wet, using worn openers, aggressive fan speeds) and excessive seed-placed fertilizer (<a href="http://research.canolacouncil.org/research-summaries-details/20/improving-canola-establishment-and-uniformity-across-various-soil-climatic-zones-of-western-canada" target="_blank">Yantai Gan et al, 2013</a>). Growers can prevent uniformity issues by ensuring uniform residue distribution the fall prior to canola, calibrating seeding equipment regularly and checking seed and fertilizer placement multiple times throughout each field.</p>
    <img src={Img} alt="Stand uniformity"/>
  </div>
);

export default StandUniformity;
