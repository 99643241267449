import React from 'react';
import Img from 'images/modal/seeding-rate.jpg'

const SeedingRate = (props) => (
  <div>
    <h1>Seeding Rate</h1>
    { /* eslint-disable-next-line react/jsx-no-target-blank */ }
    <p>The average seeding rate being used by many growers in recent years has been 5 lbs/ac but depending on how heavy your seed is, 5 lbs/ac may not give you an adequate plant stand. Heavier seed means fewer seeds per pound and while larger seed may increase early season biomass, decrease susceptibility to flea beetle damage or shorten days to the start and end of flowering (Harker et al, 2015), increased seed size has not been proven to increase germination or yield above around 3.5 grams per thousand seeds. <a href="http://www.canolacouncil.org/canola-encyclopedia/crop-establishment/" target="_blank">For more information</a></p>
    <img src={Img} alt="Seeding Rate"/>
  </div>
);

export default SeedingRate;
