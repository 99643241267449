const config = {
	baseURL: '/calculator', // You probably want to use `process.env.PUBLIC_URL` instead of this
	colors: {
		orange: '#f7c500',
		darkOrange: '#d0ab00',
		lightGrey: '#aaaaaa',
		grey: '#737373',
		darkGrey: '#3C3C3C',
		green: '#04a777',
		darkGreen: '#00553c',
		red: '#da1f26',
		linkGreen: '#4F7106',
	},
	//RESTBase: process.env.NODE_ENV === 'production' ? 'https://ccc.suckerpunch.ca/wp-json' : 'http://127.0.0.1:8890/wp-json',
	RESTBase: 'https://www.canolacouncil.org/wp-json',
	surveyEndpoint: '/wp/v2/canolacounts-survey',
	geolocateAPIBase: 'https://developer.townshipcanada.com/api/v1.0',
	geolocateAPIKey: '693d6aa1add24841bba99b86f844411c',
    meta: {
        defaults: {
            title: "Canola Calculator | Canola Council of Canada",
            description: "Minimize your risk and maximize your profit with this suite of canola tools."
        },
        targetPlantDensity: {
            title: "Target plant density | Canola Calculator",
            description: "Set a target plant density in plants per square foot or plants per square metre that fits your individual field conditions, abilities and appetite for risk."
        },
        seedingRate: {
            title: "Seeding rate and seed cost | Canola Calculator",
            description: "Calculate your optimum canola seeding rates and seed cost or use this calculator after seeding to understand your emergence."
        },
        plantSurvival: {
            title: "Plant survival emergence | Canola Calculator",
            description: "Calculate canola emergence in your fields using plant density, thousand seed weight (TSW) and seeding rate."
        },
        plantDensity: {
            title: "Seeding rate plant density | Canola Calculator",
            description: "Calculate plant or seed densities using seeding rates, thousand seed weights (TSW) and estimated emergence for each field."
        },
        combineOptimization: {
            title: "Combine optimization productivity | Canola Calculator",
            description: "Optimize your combine settings to reduce grain loss, improve grain sample quality, and overall maximize productivity during canola harvest."
        },
        harvestLoss: {
            title: "Harvest combine seed loss | Canola Calculator",
            description: "Using minimal input measurements, calculate combine seed losses in weight, volume, or seed count, so that you can adjust your settings accordingly."
        },
        canolaCounts: {
            title: "Plant counts survey | Canola Calculator",
            description: "Enter plant densities and calculate emergence in this survey of crowd-sourced canola plant establishment data."
        },
        blacklegLoss: {
            title: "Blackleg Loss Calculator | Canola Calculator",
            description: "Calculate your potential blackleg yield loss based on severity, incidence and projected yield of your canola."
        }
    }
}

export default config