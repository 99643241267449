import { combineReducers } from 'redux';

import fields from './fields';
import sidebar from './sidebar';
import showResult from './showResult';
import openAccordion from './openAccordion';
import error from './error';
import fieldName from './fieldName';
import modal from './modal';
import metric from './metric';
import beforeYouStartAccordionOpen from './beforeYouStartAccordionOpen';
import selectedAnswers from './selectedAnswers';
import seedCost from './seedCost';

import canolaCounts from './canolaCountsReducers';

const rootReducer = combineReducers({
  fields,
  seedCost,
  details: (state={}, action)=>state,
  showResult,
  openAccordion, // This is for a specific component
  sidebar,
  error,
  fieldName,
  modal,
  metric,
  selectedAnswers,
  beforeYouStartAccordionOpen,
  canolaCounts,
});

export default rootReducer;
