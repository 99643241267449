import { createStore, compose } from 'redux';

import get from 'lodash/get';

import { loadState } from './localStorage';

// import root reducer
import rootReducer from './reducers/index.js';

// Load the 'saved' from localStorage
const persistedState = loadState();

const selectedAnswers = get(persistedState, 'selectedAnswers', []);
const beforeYouStartAccordionOpen = get(persistedState, 'beforeYouStartAccordionOpen', true);

const initialTownshipState = {
	township: '',
	range: '',
	meridian: '',
	string: '',
	type: '',
}

const initialFieldState = {
	farmName: '',
	fieldNickname: '',
	township: {...initialTownshipState},
	tsw: '',
	emergence: '',
	calculateEmergence: true,
	seedingRate: '',
	season: '',
}

const initialCanolaCountsState = {
	name: '',
	email: '',
	province: '',
	fields: [ {...initialFieldState} ],
	subscribeToUpdates: false,
	enterPrize: false,
	validationErrors: false,
	submitSuccessful: null,
	didSubmit: false,
	submitError: false,
	stateLastSaved: false,
}

const defaultState = {
  fields: {
    frostRisk: 3,
    growingSeason: 3,
    insectDamage: 3,
    weedControl: 3,
    standUniformity: 3,
    targetPlantDensity: 6, // Result

    tsw: 0,
    estimatedSurvival: 60,
    seedingRate: 0, // Result

    density: 0,
    plantSurvival: 0, // Result

    desiredSeedingRate: 0,
    plantDensity: 0, // Result

    seedDensity: 0, // Result
  },
  details: {
    frostRisk: { unit: '', name: 'Early Season Frost Risk' },
    growingSeason: { unit: '', name: 'Length of Growing Season' },
    insectDamage: { unit: '', name: 'In-Season Insect Damage' },
    weedControl: { unit: '', name: 'Weed Control' },
    standUniformity: { unit: '', name: 'Stand Uniformity' },
    targetPlantDensity: { unit: 'plants/ft2', name: 'Target Plant Density' },
    tsw: { unit: 'grams', name: 'T.S.W.' },
    estimatedSurvival: { unit: '%', name: 'Estimated Emergence' },
    seedingRate: { unit: 'lbs/ac', name: 'Seeding Rate' },
    density: { unit: 'plants/ft2', name: 'Density' },
    plantSurvival: { unit: '%', name: 'Emergence' },
    desiredSeedingRate: { unit: 'lbs/ac', name: 'Desired Seeding Rate' },
    plantDensity: { unit: 'plants/ft2', name: 'Plant Density' },
    seedDensity: { unit: 'seeds/ft2', name: 'Seed Density' },
  },
  seedCost: {
    seedCost: 12
  },
  sidebar: false,
  showResult: false,
  openAccordion: '',
  error: null,
  fieldName: {
    saved: false,
    saving: false,
    value: '',
    emailing: false
  },
  modal: {
    open: false,
    selected: ''
  },

  metric: false, // ft2
  selectedAnswers,
  beforeYouStartAccordionOpen,

  canolaCounts: initialCanolaCountsState
};


// create store enhancers
// connects redux devtools from chrome extension
const enhancers = compose(
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const store = createStore(rootReducer, defaultState, enhancers); // enhancers is optional.

export { initialCanolaCountsState, initialFieldState, initialTownshipState }

export default store;
