import React from 'react';
import Img from 'images/modal/looking-for-seeds.jpg'

const ActualPlantDensity = (props) => (
  <div>
    <h1>Actual plant density</h1>
    { /* eslint-disable-next-line react/jsx-no-target-blank */ }
    <p>TThe CCC recommends targeting at least 5-8 plants/ft<sup>2</sup>. Above that, you are buying extra insurance against in-season stressors and below that you are starting to take on more and more production risk. Below around 4 plants/ft<sup>2</sup> we start to see yield potential drop off dramatically as there isn't any buffer to protect your stand from insects, environmental damage etc. Plant density should be measured by counting the number of live plants at the 2-4 leaf stage in a given area. When counting plants, agronomists and grower often use a 1/2 m2 or 1/4 m2 hoop and multiply by 2 or 4 to get the number of plants per m2 (10.76 ft<sup>2</sup> = 1 m2). <a href="http://www.canolacouncil.org/canola-encyclopedia/crop-establishment/" target="_blank">For more information</a></p>
    <img src={Img} alt="Actual plant density"/>
  </div>
);

export default ActualPlantDensity;
