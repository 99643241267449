import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ga from 'react-ga4';

const AnalyticsTracker = () => {
    const location = useLocation();

    useEffect(() => {
		ga.initialize('G-DT6FZV445B');
        ga.pageview(window.location.pathname);
    }, [location]);
};
  
export default AnalyticsTracker;