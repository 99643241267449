import { cacheNames, clientsClaim, setCacheNameDetails } from 'workbox-core';

const homepageCacheKey = 'canolaCalculatorHomePage';
const canolaCalculatorHomePageUrl = 'https://www.canolacouncil.org/home-calculator/';
const canolaCalculatorHomePageLocalUrl = '/calculator/local-home.html';

/**
 * Fetches the wordpress version of /calculator/ from the Canola Council Wordpress Website
 * Stores the response in localStorage for future use.
 * Key: canolaCalculatorHomePage
 */
async function preloadRemoteHomepage() {
    // Check if we already have the remote homepage cached
    const cache = await caches.open(cacheNames.precache);
    const cachedHtml = await cache.match(homepageCacheKey);

    // Always fetch fresh html if we are online
    if (cachedHtml && !navigator.onLine) {
        console.log("Remote homepage already cached");
        return;
    }

    // Fetch the third-party page
    // Check if we are loading from localhost

    try {
        const response = await fetch(window.location.hostname === 'localhost' ? canolaCalculatorHomePageLocalUrl : canolaCalculatorHomePageUrl);

        if (!response.ok) {
            return;
        }

        const text = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');

        // Inject script into remote homepage header
        const scriptContent = `
    document.querySelectorAll('img[loading="lazy"]').forEach(img => {
        console.log("Image load marked as EAGER");
        img.loading = 'eager';
    });
`;

        // Create a script tag as a string to insert into the HTML
        const scriptTag = `<script>${scriptContent}</script>`;

        // Insert the script as a string into the head
        doc.head.innerHTML += scriptTag;

        // Get the modified HTML
        const newHtml = doc.documentElement.outerHTML;

        await cache.put(homepageCacheKey, new Response(newHtml, { headers: { 'Content-Type': 'text/html' } }));
    } catch (error) {
        console.log('Remote homepage fetch error:', error);
    }
}


/**
 * Quick and dirty method to replace the homepage content with content we have saved from the Wordpress server
 * 
 * @returns 
 */
async function loadRemoteHomepageCache() {

    let cache = await caches.open(cacheNames.precache);
    let cachedResponse = await cache.match(homepageCacheKey);
    if (!cachedResponse) {
        // preload the remote homepage
        await preloadRemoteHomepage();
        cache = await caches.open(cacheNames.precache);
        cachedResponse = await cache.match(homepageCacheKey);    
        if(!cachedResponse) {
            return;
        }
        // return;
    }

    if (window.__contentReplaced) {
        // Content already replaced
        return;
    }

    const cachedHtml = await cachedResponse.text(); // Get the HTML as text

    window.__contentReplaced = true;

    document.open();
    document.write(cachedHtml);
    document.close();
    return;
}

/**
 * Helper function to determine if we have a homepage cache stored in localstorage
 * @returns boolean
 */
async function hasHomepageCache() {
    const cache = await caches.open(cacheNames.precache);
    const cachedHtml = await cache.match(homepageCacheKey);
    return (cachedHtml) ? true : false;
}

export { preloadRemoteHomepage, loadRemoteHomepageCache, hasHomepageCache, homepageCacheKey };